<template>
    <div class="app">
        <router-view />
    </div>
</template>

<script>
export default {
    name: "App",
};
</script>

<style lang="scss">
.app {
    min-height: 100vh;
}
</style>
